import Link from 'next/link';
import { useRouter } from 'next/router';

import { useSelector } from 'react-redux';
import { selectIsNativeApp } from '@store/nativeAppConfig/nativeAppConfig.slice';
import clsx from 'clsx';
import tw from 'twin.macro';

import Image from '@components/elements/Image/Image';
import ROUTE_URLS from '@constants/routeUrls';
import useAppConfig from '@hooks/useAppConfig';
import useAppModuleConfig from '@hooks/useAppModuleConfig';
import useMediaQuery from '@hooks/useMediaQuery';
import { pushLogoGTMEvent } from '@utils/gtm';
import { checkIsUrlExternal } from '@utils/helpers';
import { up } from '@utils/screens';

type LogoProps = {
  onLoad?: () => void;
};

const Logo = ({ onLoad }: LogoProps) => {
  const router = useRouter();
  const {
    data: {
      branding: { logos },
    },
  } = useAppConfig();
  const { logo } = useAppModuleConfig('Links');
  const logoLink = logo.link || ROUTE_URLS.HOME;
  const isUpLg = useMediaQuery(up('lg'), true);
  const isNativeApp: boolean = useSelector(selectIsNativeApp);

  if (Array.isArray(logos)) {
    return null;
  }

  const logoWrapperProps = {
    className: clsx('logo__link', !isUpLg && 'is-mobile'),
    css: tw`md:mr-6 flex`,
  };

  const logoImage = (
    <Image
      tw="max-h-14 w-auto"
      className={clsx('logo__image', !isUpLg && 'is-mobile')}
      src={isUpLg ? logos.desktop : logos.mobile}
      alt="Logo"
      width={180}
      height={56}
      onLoad={onLoad}
    />
  );

  if (isNativeApp) {
    return <span {...logoWrapperProps}>{logoImage}</span>;
  }

  if (checkIsUrlExternal(logoLink)) {
    return (
      <a
        {...logoWrapperProps}
        href={logoLink}
        target={logo.openNewCard ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        {logoImage}
      </a>
    );
  }

  return (
    <Link
      {...logoWrapperProps}
      href={logoLink}
      onClick={() => {
        pushLogoGTMEvent(logoLink);
      }}
      locale={router.locale}
    >
      {logoImage}
    </Link>
  );
};

export default Logo;
