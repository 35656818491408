import dynamic from 'next/dynamic';

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import tw from 'twin.macro';

import DisplayDownXxl from '@components/elements/DisplayOn/DisplayDownXxl';
import DisplayUpSm from '@components/elements/DisplayOn/DisplayUpSm';
import DisplayUpXxl from '@components/elements/DisplayOn/DisplayUpXxl';
import LanguageSelector from '@components/elements/LanguageSelector/LanguageSelector';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

import MoneyBoxBalance from './MoneyBoxBalance/MoneyBoxBalance';
import Nav from './Nav/Nav';
import AuthButtons from './AuthButtons';
import Logo from './Logo';

const HamburgerMenu = dynamic(() => import('./HamburgerMenu/HamburgerMenu'));

const Header = () => {
  const desktopNavLeftRef = useRef();
  const desktopNavRightRef = useRef();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { clientAllowedToChangeLanguage } =
    useAppConfigSelector(selectMultinational);
  const [showMobileOnDesktop, setShowMobileOnDesktop] = useState(false);

  const validateDesktopNavWidth = () => {
    if (window && window.innerWidth > 1400) {
      const summedInnerWidth =
        desktopNavLeftRef?.current?.offsetWidth +
        desktopNavRightRef?.current?.offsetWidth;

      if (summedInnerWidth > 1370) {
        setShowMobileOnDesktop(true);
      } else {
        window.removeEventListener('resize', validateDesktopNavWidth);
      }
    }
  };

  useEffect(() => {
    if (!showMobileOnDesktop) {
      validateDesktopNavWidth();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (showMobileOnDesktop) {
      window.removeEventListener('resize', validateDesktopNavWidth);
      return;
    }

    window.addEventListener('resize', validateDesktopNavWidth);

    return () => {
      window.removeEventListener('resize', validateDesktopNavWidth);
    };
  }, [showMobileOnDesktop]);

  if (showMobileOnDesktop) {
    return (
      <header
        id="header"
        tw="py-2.5 bg-white border-b border-gray-1 h-20 flex items-center"
      >
        <div className="container">
          <div className="row" tw="items-center justify-between flex ">
            <div className="col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <div tw="flex items-center">
                <DisplayUpSm>
                  {isAuthenticated && (
                    <MoneyBoxBalance styles={{ link: { css: tw`mr-4` } }} />
                  )}
                </DisplayUpSm>

                <HamburgerMenu />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

  return (
    <header
      id="header"
      tw="py-2.5 bg-white border-b border-gray-1 h-20 flex items-center"
    >
      <div className="container">
        <DisplayUpXxl>
          <div className="row" tw="items-center justify-between">
            <div className="col-auto" ref={desktopNavLeftRef}>
              <div tw="flex items-center">
                <Logo onLoad={validateDesktopNavWidth} />
                <Nav />
              </div>
            </div>
            <div className="col-auto" ref={desktopNavRightRef}>
              <div
                css={[
                  tw`flex items-center`,
                  isAuthenticated ? tw`-mx-2` : tw`-ml-2 -mr-3`,
                ]}
              >
                {isAuthenticated && (
                  <MoneyBoxBalance styles={{ link: { css: tw`mx-2` } }} />
                )}
                {clientAllowedToChangeLanguage && (
                  <div tw="mx-2">
                    <LanguageSelector />
                  </div>
                )}

                <AuthButtons />
              </div>
            </div>
          </div>
        </DisplayUpXxl>

        <DisplayDownXxl>
          <div className="row" tw="items-center justify-between">
            <div className="col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <div tw="flex items-center">
                <DisplayUpSm>
                  {isAuthenticated && (
                    <MoneyBoxBalance styles={{ link: { css: tw`mr-4` } }} />
                  )}
                </DisplayUpSm>

                <HamburgerMenu />
              </div>
            </div>
          </div>
        </DisplayDownXxl>
      </div>
    </header>
  );
};

export default Header;
